import { Anchor, HeaderText } from '@fsg/gui-bits';
import './DashboardPage.scss';


function DashboardPage() {

  return (
    <div>
      <HeaderText>Dashboard</HeaderText>
      <p>Choose a Customer on the left or <Anchor href="/fixtures/new" useNavLink>Create a new Customer Fixture List</Anchor></p>
    </div>
  );
}

export default DashboardPage;

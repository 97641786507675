import React, { useState, useCallback, useMemo, CSSProperties, useEffect, useContext } from "react";
import { Button, Card, TextInput, HeaderText, SubHeaderText } from "@fsg/gui-bits";
import { useNavigate, useParams } from "react-router-dom";
import Dropzone, { useDropzone } from "react-dropzone";
import "./FixtureList.scss";
import { AppContext } from "../../App/App";
import { request } from "../../api";

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 5,
  borderColor: '#aaaaaa',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

interface ICustomerFixtureLists {
  _id: number;
  customer_name: string;
  customer_number: string;
  lists: IFixtureList[];
}

interface IFixtureList {
  list_name: string;
  fixtures: IFixture[];
  _id?: string;
}

interface IFixture {
  title: string;
  heading: string;
  customerPartNumber: string;
  fsgPartNumber: string;
  imageUrl: string;
  productUrl: string;
  isNew?: boolean;
  _id?: string;
}

export default function FixtureList() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [list, setList] = useState<ICustomerFixtureLists>({} as ICustomerFixtureLists);

  const [fixtureList, setFixtureList] = useState<IFixtureList>({} as IFixtureList);

  //const [allLists, setAllLists] = useState<IFixtureList[]>([]);

  const { updateList: updateAppList } = useContext(AppContext);

  useEffect(() => {
    const fetchLists = async () => {
      const data = await request({ path: `/v1/fixtures/${id}` });
      setList(data);
      //setAllLists(data.lists);
      setFixtureList(data.lists[0])
    }
    fetchLists();
  }, [id])

  async function readFileAsDataURL(file: File) {
    let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => {

          var MAX_WIDTH = 300;
          var MAX_HEIGHT = 300;
          let img = document.createElement("img");
          img.onload = function() {
            var width = img.width;
            var height = img.height;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height = height * (MAX_WIDTH / width);
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width = width * (MAX_HEIGHT / height);
                    height = MAX_HEIGHT;
                }
            }

            let canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext("2d");
            ctx?.drawImage(img, 0, 0, width, height);

            let dataURL = canvas.toDataURL(file.type);

            resolve(dataURL);
          }
          img.src = e.target?.result as string;
        };
        fileReader.readAsDataURL(file);
    });

    return result_base64;
}

  const onDrop = useCallback(async (acceptedFiles : any, fileRejections: any, event: any) => {
    console.log(acceptedFiles);

    let fileUrls: unknown[] = [];

    for (let i = 0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i];
      const fileUrl = await readFileAsDataURL(file);
      fileUrls.push(fileUrl);
    }

    setFixtureList((fList) => {
      const list = {
        ...fList,
        fixtures: [
          ...fList.fixtures,
          ...fileUrls.map((file:any) => ({
            title: '',
            customerPartNumber: '',
            fsgPartNumber: '',
            productUrl: '',
            imageUrl: file,
            isNew: true
          }) as IFixture)
        ]
      }
    //   setAllLists((allLists) => {
    //     const newList = allLists.map((l) => {
    //       if (l === fList) {
    //         return list;
    //       }
    //       return l;
    //     })
    //     return newList;
    //   })
      return list;
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } = useDropzone({ 
    accept : {
      "images" : [".jpg", ".jpeg", ".png"],
    },
    multiple: true,
    onDrop
   } as any);

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  } as CSSProperties), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const updateList = (product:IFixture, field:string, value:string) => {
    const newFixtures = fixtureList.fixtures.map((f) => {
      if (f === product) {
        return {
          ...f,
          [field]: value
        }
      }
      return f;
    }
    );
    setFixtureList((fList) => ({
      ...fList,
      fixtures: newFixtures
    }));
  }

  const removeFixture = (product:IFixture) => {
    const newFixtures = fixtureList.fixtures.filter((f) => f !== product);
    setFixtureList((fList) => {
      const list = {
        ...fList,
        fixtures: newFixtures
      };
      //update list in allLists
      // setAllLists((allLists) => {
      //   const newList = allLists.map((l) => {
      //     if (l === fList) {
      //       return list
      //     }
      //     return l;
      //   })
      //   return newList;
      // });
      return list;
    });

  }

  const saveList = async () => {
    await request({
      path: `/v1/fixtures/${id}`,
      method: 'PUT',
      requestOptions: {
        body: JSON.stringify({
        ...list,
        lists: [fixtureList]
        })
      }
    });


    // await fetch(`http://localhost:5000/fixtures/${id}`, {
    //   method: 'PUT',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...list,
    //     lists: [fixtureList]
    //   })
    // });
    await updateAppList();
  }

  const deleteAll = async () => {

    if (window.confirm("Are you sure you want to delete all fixtures?")) {

      await request({
        path: `/v1/fixtures/${id}`,
        method: 'DELETE',
      });

      await updateAppList();
      navigate("/");
    }
  }

  // const addNewList = () => {
  //   const newListName = window.prompt("Enter a name for the new list");
  //   if (newListName) {
  //     const newList = {
  //       list_name: newListName,
  //       fixtures: [],
  //     }
  //     setAllLists((lists) => [...lists, newList]);
  //     setFixtureList(newList);
  //   }
  // }

  // const removeList = (list:IFixtureList) => {

  //   if (window.confirm("Are you sure you want to delete this list?")) {
  //     setAllLists((lists) => {
  //       return lists.filter((l) => l !== list)
  //     });
  //     if (list === fixtureList || list._id === fixtureList._id) {
  //       setFixtureList(allLists[0]);
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (allLists.length > 0) {
  //     setFixtureList(allLists[0]);
  //   }else {
  //     setFixtureList({
  //       list_name: "",
  //       fixtures: []
  //     });
  //   }

  // }, [allLists.length]);

  // useEffect(() => {
  //   setAllLists(lists => {
  //     const newList = lists.map((l) => {
  //       if (l === fixtureList) {
  //         return fixtureList;
  //       }
  //       return l;
  //     }) as IFixtureList[];

  //     return newList;
  //   })
  // }, [fixtureList]);


  return (<div>
      <HeaderText>
        <>
          {`${list?.customer_number} - ${list?.customer_name} Fixture Lists`} &nbsp;
          <Button className="save-button" onClick={saveList}>Save</Button> &nbsp;
          <Button className="delete-button" onClick={deleteAll}>Delete em All</Button>
        </>
      </HeaderText>

      {/* <ButtonGroup>
        {allLists.map((list, index) => (
          <Button buttonType={fixtureList === list ? "primary" : "secondary"} key={index} onClick={() => setFixtureList(list)}>{list.list_name}</Button>
        ))}
      </ButtonGroup>
      &nbsp;
      <Button onClick={addNewList}>Add New List</Button>
      <br />
      <br /> */}

      <SubHeaderText>
        <>
          {fixtureList?.list_name} &nbsp;
           {/* <Button className="delete-button" onClick={() => removeList(fixtureList)}>Delete List</Button> */}
        </>
      </SubHeaderText>
      <div {...getRootProps({style}) as any}>
          <input {...getInputProps() as any} />
          {
            isDragActive ?
            <p>DROP EM!</p> :
            <p>Drag 'n' drop fixture images here, or click to select files</p>
          }
        </div>
        
        <div className="fixture-list">
          { fixtureList?.fixtures?.map((product, index) => (
            <Card className="fixture-list__item" key={index}>
              <div className="fixture-image">
                <Dropzone onDrop={async (acceptedFiles) => {
                  const file = acceptedFiles[0];
                  const fileUrl = await readFileAsDataURL(file);
                  updateList(product, "imageUrl", fileUrl as string);
                }} multiple={false}>
                  {({getRootProps, getInputProps}) => (
                    <div {...getRootProps({style})}>
                        <input {...getInputProps()} />
                        <img src={product.imageUrl} alt={product.title} />
                    </div>
                )}
                </Dropzone>

                <Button buttonType={"secondary"} onClick={() => removeFixture(product)}>&times; Remove</Button>
              </div>

                <div className="fixture-list__item__details">
                  <div className="fixture-list__item__details__title">
                    <label>Fixture</label>
                    <TextInput className="input" type="text" value={product.title} onChange={(e) => updateList(product, "title", e.target.value)} />
                  </div>
                  <div className="fixture-list__item__details__heading">
                    <label>Lamp</label>
                    <TextInput className="input" type="text" value={product.heading} onChange={(e) => updateList(product, "heading", e.target.value)}  />
                  </div>
                  <div className="fixture-list__item__details__customer-part-number">
                    <label>Customer Part #</label>
                    <TextInput className="input"type="text" value={product.customerPartNumber} onChange={(e) => updateList(product, "customerPartNumber", e.target.value)}  />
                  </div>
                  <div className="fixture-list__item__details__fsg-part-number">
                    <label>FSG Part #</label>
                    <TextInput className="input" type="text" value={product.fsgPartNumber} onChange={(e) => updateList(product, "fsgPartNumber", e.target.value)}  />
                  </div>
                  <div className="fixture-list__item__details__product-url">
                    <label>Product URL</label>
                    <TextInput className="input" type="text" value={product.productUrl} onChange={(e) => updateList(product, "productUrl", e.target.value)}  />
                  </div>
                </div>
            </Card>
            ))} 
        </div>

    </div>)
};


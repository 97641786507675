import React, { useContext, useEffect, useState } from "react"
import { NavLink, Outlet, useNavigate } from "react-router-dom"
import { NavItem, NavLabel, UserMenu, Button } from "@fsg/gui-bits"
import { ReactComponent as Logo } from "../assets/logo.svg"
import { ReactComponent as Menu } from "../assets/menu.svg"
import { ReactComponent as Customer } from '../assets/customer.svg';
import "./App.scss"
import { apiClient } from "../api"
import { AuthContext } from "@fsg/apps-api-client-react"
import LoadingPage from "../pages/Auth/Loading/LoadingPage"
import Login from "../pages/Auth/LoginPage"
import { request } from "../api";

const AppAuthWrapper = () => {
  const { loading: authIsLoading, isAuthenticated } = useContext(AuthContext)

  if (authIsLoading) return <LoadingPage />

  if (!isAuthenticated) return <Login />

  return <App />
}

export const AppContext = React.createContext({
  updateList: () => {},
})

function App() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const { user } = useContext(AuthContext)

  const [ lists, setLists ] = useState<any[]>([]);
  const navigate = useNavigate();
  
  const updateList = async () => {
    const data = await request({ path : '/v1/fixtures' });

    setLists(data);
  };

  useEffect(() => {
    updateList();
  }, [])


  const UserMenuComponent = () => {
    return (
      <UserMenu
        className="user"
        name={user?.name}
        actions={[
          {
            label: `Logout`,
            onClick: () => {
              window.location.href = apiClient.logoutUri(window.location.origin)
            },
          },
        ]}
      />
    )
  }
  const UserMemo = React.memo(UserMenuComponent)

  return (
    <div className="app-wrapper">
      <div className={`navigation ${mobileNavOpen ? `mobile-open` : ``}`}>
        <NavLink className="logo" to="/">
          <Logo />
        </NavLink>
        <div className="navigation-links">
          <NavLabel icon={ Customer } className="nav-label">
            Customers
          </NavLabel>

          {lists.sort(x => x.customer_number).map((list: any) => (
            <NavItem className="nav-item" to={`/fixtures/${list._id}`}>
             { `${list.customer_number} - ${list.customer_name} ` }
            </NavItem>
          ))}
        </div>
        <Button onClick={() => {  navigate("/fixtures/new") }}>Add New Fixture Customer</Button>

        <div className="spacer" />
        <UserMemo />
      </div>
      <main>
        <AppContext.Provider value={{ updateList }}>
          <Outlet />
        </AppContext.Provider>

        <footer className="mobile-navigation">
          <NavLink className="logo" to="/">
            <Logo />
          </NavLink>
          <Button
            withIcon
            buttonType="secondary"
            onClick={() => setMobileNavOpen(!mobileNavOpen)}
          >
            <Menu />
          </Button>
        </footer>
      </main>
    </div>
  )
}

export default AppAuthWrapper;

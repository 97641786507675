import { Button, HeaderText, TextInput } from "@fsg/gui-bits";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { request } from "../../api";
import { AppContext } from "../../App/App";
import './AddList.scss';

export function AddList() {


  const [customerNumber, setCustomerNumber] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [listName, setListName] = useState('');
  const [error, setError] = useState('');
  const { updateList } = useContext(AppContext);

  const navigate = useNavigate();

  const create = async () => {
    setError('');

    const data = await request({
      path: '/v1/fixtures',
      method: 'POST',
      requestOptions: {
        body: JSON.stringify({
          customer_number: customerNumber,
          customer_name: customerName,
          lists: [
            {
              list_name: listName,
              fixtures: []
            }
          ]
        })
      }
    });

      await updateList();
      navigate(`/fixtures/${data._id}`);
    // } else {
    //   setError(data.message);
    // }
  };

  return (
    <div className="add-list">
      <HeaderText>Create a new Customer Fixture List</HeaderText>

      {error && <div className="error">{error}</div>}

      <div className="add-list__form">
        <label>
          <div className="add-list__form__label">Customer Number</div>
          <TextInput value={customerNumber} onChange={(e) => setCustomerNumber(e.target.value)} placeholder="Customer Number" />
        </label>
        <label>
          <div className="add-list__form__label">Customer Name</div>
          <TextInput value={customerName} onChange={(e) => setCustomerName(e.target.value)} placeholder="Customer Name" />
        </label>
        <label>
          <div className="add-list__form__label">List Name</div>
          <TextInput value={listName} onChange={(e) => setListName(e.target.value)} placeholder="List Name" />
        </label>
      </div>
      <Button onClick={create}>Create</Button>
    </div>
  )
}
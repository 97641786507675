import App from '../App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import FixtureList from '../pages/FixtureList';
import { AddList } from '../pages/AddList/AddList';
import DashboardPage from '../pages/DashboardPage';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <App /> }>
          <Route path="" element={ <DashboardPage /> } />
          <Route path="/fixtures/new" element={ <AddList />} />
          <Route path="/fixtures/:id" element={ <FixtureList /> } />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
